import Main from "./pages/Main";
import {
  CLIENTS_ROUTE,
  LOGIN_ROUTE,
  MAIN_ROUTE,
  CONSULTATION_ROUTE,
} from "./utils/consts";
import Clients from "./pages/Clients";
import Login from "./pages/Login";
import Consultation from "./pages/Consultation";

export const authRoutes = [
  {
    path: MAIN_ROUTE,
    Component: Main,
  },
  {
    path: CLIENTS_ROUTE,
    Component: Clients,
  },
  {
    path: CONSULTATION_ROUTE,
    Component: Consultation,
  },
];

export const publicRoutes = [
  {
    path: LOGIN_ROUTE,
    Component: Login,
  },
];
