import React from "react";
import "./styles/Footer.css"

const Footer = () => {
    return (
        <div style={{ position: "fixed", bottom: 0, width: "100%", textAlign: "center", paddingBottom: 10 }}>
            <span className="footerText">Powered by</span> <span className="footerLogoText">DAROBOTS</span>
        </div>
    );
};

export default Footer;