import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { Button, Container, Form, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { login } from "../http/adminAPI";
import { Context } from "../index";
import { MAIN_ROUTE } from "../utils/consts";
import "./styles/Login.css";

const Login = observer(() => {
  const { admin } = useContext(Context);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const signIn = async () => {
    try {

      await login(username, password).then(data => {
        admin.setAdmin(data.admin);
        admin.setIsAuth(true);
        navigate(MAIN_ROUTE);
      }).catch(e => {
        alert(e.response.data.message);
      });

    } catch (e) {
      alert(e.response.data.message);
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      await signIn();
    }
  };

  return (
    <Container className="login-container">
      <div className="logoImage">
        <img src="/navImg/logo.png" alt="Logo" className="logoLogin" />
      </div>
      <div className="loginBlock">
        <div className="loginText">
          <span>Авторизація в адмін-панель</span>
        </div>

        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              className="loginPageInput"
              type="text"
              placeholder="Логін"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="passwordBlock">
            <InputGroup>
              <Form.Control
                className="loginPageInput"
                type={showPassword ? "text" : "password"}
                placeholder="Пароль"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="passwordBtn"
              >
                <img
                  src={
                    showPassword
                      ? "/loginImg/eye.slash.svg"
                      : "/loginImg/eye.svg"
                  }
                  alt={showPassword ? "Скрыть пароль" : "Показать пароль"}
                />
              </button>
            </InputGroup>
          </Form.Group>

          <Button className="signBtn" onClick={signIn}>
            Увійти
          </Button>
        </Form>
      </div>
    </Container>
  );
});

export default Login;
