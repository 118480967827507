import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import AdminStore from "./store/AdminStore";
import UserStore from "./store/UserStore";
import UserInvoiceStore from "./store/InvoiceStore";


export const Context = createContext(null)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <Context.Provider value={{
        admin: new AdminStore(),
        users: new UserStore(),
        userInvoice: new UserInvoiceStore()

    }}>
        <App />
    </Context.Provider>

);

