import { observer } from "mobx-react-lite"
import React, { useContext, useEffect, useState } from "react"
import { Table } from "react-bootstrap"
import UserRow from "../components/UserRow"
import { addSubDays, blockUser, deleteUser, getUsers, getUsersInvoice, addToGrop, removeToGrop, changePlan } from "../http/userAPI"
import { Context } from "../index"
import "./styles/Clients.css"


const Clients = observer(() => {
  const { users, userInvoice } = useContext(Context)
  const [sortBy, setSortBy] = useState("id")
  const [sortOrder, setSortOrder] = useState("asc")
  const [currentPage, setCurrentPage] = useState(0)
  const usersPerPage = 10

  useEffect(() => {
    getUsers().then((data) => {
      users.setUser(data);
    });
  }, [users]);

  useEffect(() => {
    getUsersInvoice().then((data) => {
      console.log(data)
      userInvoice.setInvoices(data); // Загружаем счета и сохраняем в состояние
    });
  }, [userInvoice]);

  // Проверяем, что данные счетов загружены
  const invoices = userInvoice?.invoices.filter(invoice => invoice.status === 'approved') || [];

  const activity = userInvoice?.invoices.filter(invoice => invoice.status === 'approved' || invoice.status === 'waiting_for_payment') || [];

  const handleDeleteUser = async (userId) => {
    await deleteUser(userId)
  }

  const handleAddUser = async (userId) => {
    await addToGrop(userId)
  }

  const handleRemoveUser = async (userId) => {
    await removeToGrop(userId)
  }

  const handleAddSubscription = async (userId, days) => {
    await addSubDays(userId, days)
  }

  const handleBlockUser = async (userId) => {
    await blockUser(userId, true)
  }
  const handlechangePlan = async (userId, plan) => {
    await changePlan(userId, plan)
  }

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc")
    } else {
      setSortBy(column)
      setSortOrder("asc")
    }
  }

  const sortedUsers = [...users.users].sort((a, b) => {
    if (sortOrder === "asc") {
      return a[sortBy] > b[sortBy] ? 1 : -1
    } else {
      return a[sortBy] < b[sortBy] ? 1 : -1
    }
  })

  const getSortSymbol = (column) => {
    if (sortBy === column) {
      return sortOrder === "asc" ? "▲" : "▼"
    }
    return ""
  }

  const indexOfLastUser = (currentPage + 1) * usersPerPage
  const indexOfFirstUser = indexOfLastUser - usersPerPage
  //const currentUsers = sortedUsers.slice(indexOfFirstUser, indexOfLastUser)

  const currentUsers = sortedUsers.slice(currentPage * usersPerPage, (currentPage + 1) * usersPerPage);

  const pageNumbers = Math.ceil(sortedUsers.length / usersPerPage);
  const pageButtons = Array.from({ length: pageNumbers }, (_, i) => i);

  // Сопоставляем пользователей с их одобренными счетами

  const usersWithInvoices  = currentUsers.map((user) => {
    // Находим все одобренные счета для данного пользователя
    const approvedInvoices = invoices.filter((inv) => inv.telegramId === user.telegramId);

    // Находим все активности для данного пользователя
    const userActivities = activity.filter((act) => act.telegramId === user.telegramId);

    // Определяем последнюю активность (предполагается, что поле date содержит дату активности)
    const latestActivity = userActivities.sort((a, b) => new Date(b.date) - new Date(a.date))[0] || null;

    return {
      ...user,
      approvedInvoices,
      userActivities
    };
  });




  return (
    <div>
      <div className="user-table">
        <Table hover page-size="4">
          <thead>
            <tr>
              <th onClick={() => handleSort("name")} style={{ width: "18vw" }}>
                Ім'я
                {getSortSymbol("name")}
              </th>
              <th
                onClick={() => handleSort("telegramId")}
                style={{ width: "14vw" }}
              >
                Telegram ID
                {getSortSymbol("telegramId")}
              </th>
              <th
                onClick={() => handleSort("birthDate")}
                style={{ width: "16vw" }}
              >
                Дата народження
                {getSortSymbol("activePlan")}
              </th>
              <th onClick={() => handleSort("activePlan")} style={{ width: "16vw" }}>
                Тип підписки
                {getSortSymbol("activePlan")}
              </th>
              <th
                onClick={() => handleSort("leftDays")}
                style={{ width: "14vw" }}
              >
                Тривалість підписки
                {getSortSymbol("leftDays")}
              </th>
              <th
                onClick={() => handleSort("subscribeEndDate")}
                style={{ width: "14vw" }}
              >
                Дата закінчення оплати
                {getSortSymbol("subscribeEndDate")}
              </th>
              <th
                style={{ width: "14vw" }}
              >
                Попередні підписки

              </th>

              <th style={{ width: "20vw" }}>Дії</th>
            </tr>
          </thead>

          <tbody>
            {usersWithInvoices.map((user) => (
              <UserRow
                key={user.id}
                user={user}
                onDeleteUser={handleDeleteUser}
                onAddSubscription={handleAddSubscription}
                onBlockUser={handleBlockUser}
                OnAddUser={handleAddUser}
                OnRemoveUser={handleRemoveUser}
                OnChangePlan={handlechangePlan}
                invoice={user.invoice}

              />
            ))}
          </tbody>
        </Table>
      </div>
      <div className="pagination-container">
        {pageButtons.map((page) => (
          <button
            key={page}
            onClick={() => setCurrentPage(page)}
            className={currentPage === page ? "selectedPage" : ""}
          >
            {page + 1}
          </button>
        ))}
      </div>
    </div>
  )
})

export default Clients
