import React, { useContext } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Context } from "../index";
import { Link, useLocation } from "react-router-dom";
import "./styles/NavBar.css";
import { CLIENTS_ROUTE, CONSULTATION_ROUTE, MAIN_ROUTE } from "../utils/consts";

const NavBar = () => {
  const { admin } = useContext(Context);
  const location = useLocation();
  console.log(location.pathname);
  const handleLogout = () => {
    admin.setAdmin(null);
    admin.setIsAuth(false);
    localStorage.removeItem("token");
    localStorage.removeItem("adminUsername");
  };

  return (
    <Navbar>
      <Nav>
        <div className="leftSide">
          <Link
            to={MAIN_ROUTE}
            className={`navBtn ${
              location.pathname === MAIN_ROUTE ? "selectedPage" : ""
            }`}
          >
            Дашборд
          </Link>
          <Link
            to={CLIENTS_ROUTE}
            className={`navBtn spacedBtn ${
              location.pathname === CLIENTS_ROUTE ? "selectedPage" : ""
            }`}
          >
            Клієнти
          </Link>
          {/* <Link
            to={CONSULTATION_ROUTE}
            className={`navBtn spacedBtn ${
              location.pathname === CONSULTATION_ROUTE ? "selectedPage" : ""
            }`}
          >
            Консультації
          </Link> */}
        </div>
        <div className="image-block">
          <img src="/navImg/logo.png" alt="Logo" className="logo" />
        </div>
        <div className="rightSide">
          <span className="adminText">
            {localStorage.getItem("adminUsername")}
          </span>
          <button onClick={handleLogout} className="navBtn">
            <span>Вийти</span>
          </button>
        </div>
      </Nav>
    </Navbar>
  );
};

export default NavBar;
