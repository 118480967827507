import React, { useContext, useEffect } from "react";
import { Spinner, Table } from "react-bootstrap";
import { Context } from "../index";
import { getUsers } from "../http/userAPI";
import { observer } from "mobx-react-lite";

const daysOfWeek = ["Monday", "Wednesday", "Friday"];
const times = ["12", "13", "14", "15", "16", "17", "18"];

const Consultation = observer(() => {
  const { users } = useContext(Context);

  useEffect(() => {
    getUsers()
      .then((data) => {
        users.setUser(data);
      })
      .catch((error) => console.log(error));
  }, []);

  function getDayOfWeek(date) {
    const dayOfWeeks = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return dayOfWeeks[date.getDay()];
  }

  const generateDates = () => {
    const now = new Date();
    const dates = [];

    let currentDate = new Date(now);

    for (let i = 0; i < 3; i++) {
      currentDate.setDate(currentDate.getDate() + i);

      while (!daysOfWeek.includes(getDayOfWeek(currentDate))) {
        currentDate.setDate(currentDate.getDate() + 1);
      }

      const dateString = formatDate(currentDate);

      dates.push({ day: getDayOfWeek(currentDate), date: dateString });
    }
    console.log(dates);
    return dates;
  };

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day < 10 ? "0" + day : day}.${
      month < 10 ? "0" + month : month
    }.${year}`;
  };

  const formatTime = (date) => {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const appointmentsByTimeSlot =
    users.users.length &&
    users.users.reduce((acc, user) => {
      if (user.consultation) {
        const appointmentDate = new Date(Number(user.consultation));
        if (new Date() - appointmentDate > 1800000) {
          return acc;
        }
        const dates = generateDates();

        const dayName = getDayOfWeek(appointmentDate);

        const dayIndex = dates.findIndex((date) => date.day === dayName);

        const time = formatTime(appointmentDate);

        if (!acc[dayIndex]) {
          acc[dayIndex] = { appointmentsByTime: {} };
        }

        if (!acc[dayIndex].appointmentsByTime[time]) {
          acc[dayIndex].appointmentsByTime[time] = [];
        }

        acc[dayIndex].appointmentsByTime[time].push({
          name: user.name,
          email: user.email,
        });
      }

      return acc;
    }, {});

  if (!users.users.length) {
    return <Spinner />;
  }

  return (
    <div
      style={{ backgroundColor: "white", margin: "10px", borderRadius: "30px" }}
    >
      <Table>
        <thead>
          <tr>
            <th>Time</th>
            {generateDates().map((date, index) => (
              <th key={index}>
                {date.day} <br />
                {date.date}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {times.map((time) => (
            <tr key={time} style={{ height: "60px" }}>
              <td>{`${time}:00`}</td>
              {daysOfWeek.map((day, index) => {
                const appointments =
                  appointmentsByTimeSlot[index]?.appointmentsByTime?.[
                    `${time}:00`
                  ] || [];

                const isReserved = appointments.length > 0 ? "reserved" : "";

                return (
                  <td key={`${day}-${time}`} className={isReserved}>
                    {appointments.map((appointment, index) => (
                      <div key={index}>
                        <div>
                          <strong>{appointment.name}</strong>
                        </div>
                        <div>{appointment.email}</div>
                      </div>
                    ))}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
});

export default Consultation;
