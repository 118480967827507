import React, { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'
import { authRoutes, publicRoutes } from "../routes";
import { LOGIN_ROUTE, MAIN_ROUTE } from "../utils/consts";
import { Context } from "../index";

const AppRouter = () => {
    const { admin } = useContext(Context);

    return (
        <Routes>
            {admin.isAuth ? (
                authRoutes.map(({ path, Component }) => (
                    <Route key={path} path={path} element={<Component />} />
                ))
            ) : (
                publicRoutes.map(({ path, Component }) => (
                    <Route key={path} path={path} element={<Component />} />
                ))
            )}
            <Route path="*" element={<Navigate to={admin.isAuth ? MAIN_ROUTE : LOGIN_ROUTE} replace />} />
        </Routes>
    );
};


export default AppRouter;