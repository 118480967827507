import { useState } from "react"
import { InputGroup, Modal } from "react-bootstrap"
import "./styles/UserRow.css"
import { format } from 'date-fns';


const UserRow = ({ user, onDeleteUser, onAddSubscription, onBlockUser, OnAddUser, OnRemoveUser, invoice, OnChangePlan }) => {
  const [showModal, setShowModal] = useState(false)
  const [subscriptionDays, setSubscriptionDays] = useState()
  const [selectedAction, setSelectedAction] = useState(null)
  const [selectedPlan, setSelectedPlan] = useState(null);


  const handleAddUser = () => {
    OnAddUser(user.id)
  }

  const handleDeleteUser = () => {
    onDeleteUser(user.id)
  }

  const handleAddSubscription = () => {
    onAddSubscription(user.id, subscriptionDays)
  }

  const handleRemovedUser = () => {

    OnRemoveUser(user.id)
  }

  const handleBlockUser = () => {
    onBlockUser(user.id)
  }


  const handleSubscriptionChange = (userId, newPlan) => {
    console.log(`Changing plan for user ${userId} to ${newPlan}`);
    // Здесь могут быть вызовы API для обновления типа подписки пользователя
  };

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);  // Store the selected plan
    setShowModal(true);      // Keep the modal open for confirmation
    setSelectedAction("confirmChangeSubscription");  // Set action to confirmation
  };

  const confirmSubscriptionChange = async () => {
    if (selectedPlan) {
      console.log(`Confirming change to ${selectedPlan} for user ${user.id}`);
      try {
        const response = await OnChangePlan(user.id, selectedPlan);
        console.log('Subscription changed successfully:', response);
        setShowModal(false); // Close the modal after successful change
        await new Promise((r) => setTimeout(r, 1000));
        window.location.reload(true);
      } catch (error) {
        console.error('Failed to change subscription:', error);
        // Здесь можно обработать ошибку, например, показать сообщение пользователю
      }
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'dd.MM.yyyy');
  }

  const handleShowPreviousSubs = () => {
    setSelectedAction("showPreviousSubs");
    setShowModal(true);
  }

  const handleShowLatestActivity = () => {
    setSelectedAction("showLatestActivity")
    setShowModal(true)
  }

  // Функция для извлечения срока подписки из описания
  const extractSubscriptionDuration = (description) => {
    const regex = /(\d+)\s+місяць|місяці|місяців/; // Группируем варианты слов "місяць", "місяці", "місяців"
    const match = description.match(/(\d+)\s+(місяць|місяці|місяців)/); // Ищем число и слово "місяць", "місяці" или "місяців"
    return match ? match[0] : 'Не визначено'; // Возвращаем найденную строку или "Не визначено"
  };

  const subscriptionTypes = {
    Platinum: '/path/to/platinum.png',
    Gold: '/path/to/gold.png',
    Silver: '/path/to/silver.png',
    Bronze: '/path/to/bronze.png'
  };

  return (
    <tr key={user.id}>
      <td>{user.name}</td>
      <td>{user.telegramId}</td>
      <td>{user.birthDate || "-"}</td>
      <td>{user.activePlan || "-"}</td>
      <td>{user.leftDays}</td>
      <td>{user.subscribeEndDate ? formatDate(user.subscribeEndDate) : "-"}</td>
      <td>
        <button
          onClick={handleShowPreviousSubs}
          className="viewPreviousSubsBtn"
        >
          <span>📜 Показати підписки</span>
        </button>

      </td>
      <td>
        <div className="actionBlock">
          <InputGroup className="addDaysForm">
            <input
              className="addDaysInput"
              type="text"
              placeholder="Дні"
              value={subscriptionDays}
              onChange={(e) => setSubscriptionDays(e.target.value)}
            ></input>
            <button
              onClick={() => {
                setShowModal(true)
                setSelectedAction("addSubscription")
              }}
              className="addDaysBtn"
            >
              <img
                src="/clientsImg/plus.svg"
                alt="Add subscribe days"
                title="Add subscribe days"
              />
            </button>
          </InputGroup>

          <button
            onClick={() => {
              setShowModal(true)
              setSelectedAction("block")
            }}
            className="blockUserBtn"
          >
            <img
              src="/clientsImg/block.svg"
              alt="Block user"
              title="Block user"
            />
          </button>

          <button
            onClick={() => {
              setShowModal(true)
              setSelectedAction("delete")
            }}
            className="deleteUserBtn"
          >
            <img
              src="/clientsImg/trash.svg"
              alt="Delete user"
              title="Delete user"
            />
          </button>

          <button
            onClick={() => {
              setShowModal(true)
              setSelectedAction("add")
            }}
            className="addUserBtn"
          >
            <img
              src="/clientsImg/join.png"
              alt="Додати користувача до чату"
              title="Додати користувача до чату"
            />
          </button>

          <button
            onClick={() => {
              setShowModal(true)
              setSelectedAction("remove")
            }}
            className="RemoveUserBtn"
          >
            <img
              src="/clientsImg/remove.png"
              alt="Видалити користувача з чату"
              title="Видалити користувача з чату"
            />
          </button>
          <button
            onClick={() => {
              setShowModal(true);
              setSelectedAction("changeSubscription");
            }}
            className="changeSubscriptionBtn"
          >
            <img
              src="/clientsImg/sync.png" // Путь к вашему изображению для кнопки смены подписки
              alt="Змінити підписку"
              title="Змінити підписку"
            />
          </button>
          <button
            onClick={handleShowLatestActivity}
            className="latestActivityBtn"
          >
            <img
              src="/clientsImg/shopping-cart.png"
              alt="Показати останню активність"
              title="Показати останню активність"
            />
          </button>



        </div>
      </td>
      <Modal
        className="modalWindow"
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size={
          selectedAction === "showPreviousSubs" || selectedAction === "showLatestActivity"
            ? "lg"
            : undefined
        }
      >
        <Modal.Header>
          {selectedAction === "delete"
            ? "Вигнати його з нашого району?"
            : selectedAction === "addSubscription"
              ? "Нагородити його днями?"
              : selectedAction === "add"
                ? "Додати користувача до чату?"
                : selectedAction === "remove"
                  ? "Видалити користувача з чату?"
                  : selectedAction === "block"
                    ? "Заблокувати цього порушника?"
                    : selectedAction === "showPreviousSubs"
                      ? "Попередні підписки"
                      : selectedAction === "showLatestActivity"
                        ? "Остання активність"
                        : ""}
        </Modal.Header>

        <Modal.Body>
          {selectedAction === "changeSubscription" && (
            <div>
              <h4>Виберіть новий тип підписки</h4>
              <div className="subscriptionOptions">
                {Object.entries(subscriptionTypes).map(([plan, icon]) => (
                  <button
                    key={plan}
                    onClick={() => handleSelectPlan(plan)}
                    className={`subscriptionOptionBtn ${plan.toLowerCase()}`}
                  >
                    {plan}
                  </button>
                ))}
              </div>
            </div>
          )}
          {selectedAction === "confirmChangeSubscription" && selectedPlan && (
            <div>
              <h4>Підтвердіть зміну підписки на {selectedPlan}</h4>
              <button onClick={confirmSubscriptionChange} className="confirmChangeBtn">
                Підтвердити
              </button>
              <button onClick={() => setSelectedAction("changeSubscription")} className="cancelChangeBtn">
                Відмінити
              </button>
            </div>
          )}
          {selectedAction === "showPreviousSubs" && (
            <div>
              <h5 style={{
                textAlign: "left",
                marginBottom: "20px",
                fontSize: "18px",
                fontWeight: "bold",
                color: "#333"
              }}>
                Загальна сума:
                <span style={{
                  fontWeight: "bold",
                  color: "#007bff"
                }}>
                  {user.totalAmount ? user.totalAmount + " UAH" : " Немає даних"}
                </span>
              </h5>
              {user.approvedInvoices.length > 0 ? (
                <div>
                  <ul style={{ textAlign: "left", paddingLeft: "20px" }}>
                    {user.approvedInvoices.map((invoice, index) => (
                      <li key={index} style={{ marginBottom: "10px", lineHeight: "1.6" }}>
                        <strong>Сума рахунку:</strong> {invoice.amount} UAH,
                        <strong> Дата:</strong> {formatDate(invoice.paidDate)},
                        <strong> Тип підписки:</strong> {invoice.plan} ({extractSubscriptionDuration(invoice.description)})
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <p>Немає одобрених рахунків</p>
              )}
            </div>
          )}
          {selectedAction === "showLatestActivity" && (
            <div>
              {user.userActivities.length > 0 ? (
                <div>
                  <ul style={{ textAlign: "left", paddingLeft: "20px" }}>
                    {user.userActivities.map((invoice, index) => (
                      <li key={index} style={{ marginBottom: "10px", lineHeight: "1.6" }}>
                        <strong>Сума рахунку:</strong> {invoice.amount} UAH,
                        <strong> Дата:</strong> {formatDate(invoice.createdAt)},
                        <strong> Тип підписки:</strong> {invoice.plan} ({extractSubscriptionDuration(invoice.description)})
                        <strong> Статус:</strong>
                        <span style={{
                          color: invoice.status === "waiting_for_payment" ? "red" : invoice.status === "approved" ? "green" : "black",
                          fontWeight: "bold"
                        }}>
                          {invoice.status === "waiting_for_payment" ? " Не оплачено" : invoice.status === "approved" ? " Оплачено" : "Невідомо"}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <p>Немає даних про останню активність</p>
              )}
            </div>
          )}

          {/* Другие возможные содержимые Modal.Body */}
        </Modal.Body>

        <Modal.Footer>
          {["showPreviousSubs", "showLatestActivity", "changeSubscription", "confirmChangeSubscription"].includes(selectedAction) ? (
            <button
              onClick={() => setShowModal(false)}
              className="red-close-button"
            >
              Закрити
            </button>
          ) : (
            <>
              <button
                onClick={async () => {
                  if (selectedAction === "delete") {
                    handleDeleteUser();
                  } else if (selectedAction === "addSubscription") {
                    handleAddSubscription();
                  } else if (selectedAction === "add") {
                    handleAddUser();
                  } else if (selectedAction === "remove") {
                    handleRemovedUser();
                  } else if (selectedAction === "block") {
                    handleBlockUser();
                  }
                  setShowModal(false);
                  await new Promise((r) => setTimeout(r, 1000));
                  window.location.reload(false);
                }}
              >
                {selectedAction === "delete"
                  ? "Так, видалити"
                  : selectedAction === "addSubscription"
                    ? "Так, він обраний"
                    : selectedAction === "add"
                      ? "Так, додати його"
                      : selectedAction === "remove"
                        ? "Так, видалити його"
                        : selectedAction === "block"
                          ? "Так, в бан його"
                          : "Закрити"}
              </button>
              <button onClick={() => setShowModal(false)}>
                {selectedAction === "delete"
                  ? "Ні, він ще потрібен"
                  : selectedAction === "addSubscription"
                    ? "Ні, обійдеться"
                    : selectedAction === "add"
                      ? "Ні, не додавати його"
                      : selectedAction === "remove"
                        ? "Ні, не видаляти його"
                        : selectedAction === "block"
                          ? "Ні, він не винен"
                          : "Закрити"}
              </button>
            </>
          )}
        </Modal.Footer>

      </Modal>
    </tr>
  )
}

export default UserRow