import {$authHost} from "./index";

export const getUsers = async() => {
    const {data} = await $authHost.get('api/user')
    return data
}

export const getUsersInvoice = async() => {
    const {data} = await $authHost.get('api/user-invoice')
    return data
}

export const removeSubscribe = async (id) => {
    await $authHost.post(`api/user/${id}/kick`)
}

export const addToGrop = async (id) => {
    await $authHost.post(`api/user/${id}/add`)
}


export const changePlan = async (id, plan) => {
    const response = await $authHost.post(`api/user/${id}/plan`, { plan });
    return response.data; // Возвращаем ответ сервера для дальнейшей обработки
}


export const removeToGrop = async (id) => {
    await $authHost.post(`api/user/${id}/remove`)
}

export const addSubDays = async (id, days) => {
    await $authHost.post(`api/user/${id}/days`, {days})
}

export const deleteUser = async (id) => {
    await $authHost.post(`api/user/${id}/delete`)
}

export const blockUser = async (id, status) => {
    await $authHost.post(`api/user/${id}/block`, {status})
}

export const getChannelMembers = async () => {
    const {data} = await $authHost.get('api/user/channelMembers')
    return data
}